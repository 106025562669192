<template>
  <div ref="searchBarRef" class="SearchWrapper">
    <Icon :icon="mapIcon.search" class="SearchIcon" />
    <Input
      v-bind="{ ...props, ...attrs }"
      v-model="search"
      autofocus
      type="text"
      class="SearchInput"
      @keydown.esc.prevent="clear()"
    />
    <Icon v-if="!hideClearButton" :icon="mapIcon.remove" class="ClearIcon" @click="clear()" />
  </div>
</template>

<script setup lang="ts">
import type { Props as InputProps, Emits as InputEmits } from "~/components/ui/input/Input.vue";

export type Props = InputProps & { hideClearButton?: boolean };

export type Emits = InputEmits & { onClear: [] };

const props = withDefaults(defineProps<Props>(), { placeholder: "Rechercher" });

const emit = defineEmits<Emits>();

const attrs = useAttrs();

const { modelValue: search } = useVModels(props, emit);

const searchBarRef = ref();

const { width } = useElementSize(searchBarRef);

const clear = function () {
  search.value = "";
  emit("onClear");
};

defineExpose({ width });
</script>

<style scoped lang="scss">
.SearchWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.SearchInput {
  font-size: 16px;
  height: 38px;
  border-radius: 12px;
  min-width: 300px;
  padding-left: 28px;
  border: 1px solid var(--border);
  transition: var(--transition-1);
  flex: 1;

  &::placeholder {
    color: var(--gray-4);
  }

  &:hover {
    border: 1px solid var(--border-hover);
  }

  &:focus {
    outline: 2px solid var(--blue);
  }
}

.SearchIcon {
  position: absolute;
  left: 10px; // Left is 10px instead of 8px to compensate for the margin-left: 2px introduced to compensate for the outline-offset: 2px;
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  color: var(--gray-3);
}

.ClearIcon {
  position: absolute;
  right: 8px;
  height: 18px;
  width: 18px;
  flex-shrink: 0;
  color: var(--gray-4);
  cursor: pointer;
  transition: var(--transition-1);

  &:hover {
    color: var(--gray-3);
  }
}
</style>
